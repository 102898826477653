import {definePreset} from "@primeuix/themes";
import Aura from "@primeuix/themes/aura";
import PrimeVue from "primevue/config";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import {type ComponentPublicInstance, createApp} from "vue";

const TtrSuccessPrimeVueTheme = definePreset(Aura, {
  semantic: {
    primary: {
      50: "#E6D4FF",
      100: "#DABFFF",
      200: "#C396FF",
      300: "#AB6EFF",
      400: "#9445FF",
      500: "#7C1CFF",
      600: "#6000E3",
      700: "#4800AB",
      800: "#310073",
      900: "#19003B",
      950: "#0D001F",
    },
    info: {
      50: "#94C7FF",
      100: "#7FBDFF",
      200: "#57A8FF",
      300: "#2E93FF",
      400: "#057EFF",
      500: "#006ADB",
      600: "#0056B2",
      700: "#00428A",
      800: "#002F61",
      900: "#001B38",
      950: "#000D1C",
    },
    colorScheme: {
      light: {
        surface: {
          0: "#ffffff",
          50: "{neutral.50}",
          100: "{neutral.100}",
          200: "{neutral.200}",
          300: "{neutral.300}",
          400: "{neutral.400}",
          500: "{neutral.500}",
          600: "{neutral.600}",
          700: "{neutral.700}",
          800: "{neutral.800}",
          900: "{neutral.900}",
          950: "{neutral.950}",
        },
      },
      dark: {
        surface: {
          0: "#ffffff",
          50: "{neutral.50}",
          100: "{neutral.100}",
          200: "{neutral.200}",
          300: "{neutral.300}",
          400: "{neutral.400}",
          500: "{neutral.500}",
          600: "{neutral.600}",
          700: "{neutral.700}",
          800: "{neutral.800}",
          900: "{neutral.900}",
          950: "{neutral.950}",
        },
      },
    },
  },
});

const tempApp = createApp({
  el: "#tempAppElement",
  name: "TtrToast",
  methods: {
    launchToast(message: string, severity: "error" | "success") {
      this.$toast.add({
        severity: severity,
        summary: severity === "error" ? "Error" : "Success",
        detail: message,
        life: 5000,
      });
    },
  },
});

tempApp.use(PrimeVue, {
  theme: {
    preset: TtrSuccessPrimeVueTheme,
    options: {
      darkModeSelector: ".dark",
      cssLayer: {
        name: "primevue",
        order: "base, primevue",
      },
    },
  },
  ripple: true,
});
tempApp.use(ToastService);
tempApp.component("PToast", Toast);

let vm: ComponentPublicInstance<any> | null = null;

export const launchToast = (message: string, severity: "error" | "success") => {
  //find "main" element and append the tempAppElement to it.
  const appContainer = document.querySelector(".toast-container");
  if (appContainer == null) {
    return;
  }

  if (vm == null) {
    //temporary vue app.
    const tempAppElement = document.createElement("div");
    tempAppElement.id = "tempAppElement";
    tempAppElement.innerHTML = '<p-toast position="top-right" style="top: 102px;" class="z-50"></p-toast>';
    appContainer.appendChild(tempAppElement);
    vm = tempApp.mount("#tempAppElement");
  }

  vm.launchToast(message, severity);
};
